import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../styles/ExperienceSection.css';
import events from "../../assets/images/events.png"
import podCast from "../../assets/images/podcasts.png"
import curatedPacks from "../../assets/images/curated-packs.png"
import connectorImage from "../../assets/images/Vector 3 (1).png"
import connectorImage2 from "../../assets/images/Vector 4.png";
import lastImage from "../../assets/images/image-4.png";



const ExperienceSection = () => {
  return (
    <section className="experience-section py-5" style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}>
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className='main-headings text-light'>What you'll experience at <span className='main-headings-gradient'>TheRevFolks</span>  </h1>
          </Col>
        </Row>
        <Row className="mt-5 pt-4">
          <Col md={12} className="d-xl-flex d-lg-flex d-md-block d-sm-block position-relative">
            <Col md={12} lg={6} xl={6} sm={12} className='d-flex justify-content-end remove-padding-right'>
              <Card.Img
                variant="top"
                src={events}
                className='img-fluid image-1 '
                style={{
                  maxHeight: "300px",
                  maxWidth: "480px",
                  borderRadius: "10px",
                  padding: "2px",
                  background: "linear-gradient(to right, #B6F3C9, #4763F4)",
                }}
              />
            </Col>
            <Col md={12} lg={6} xl={6} sm={12} className='remove-padding'>
              <div className="col-12 d-flex align-items-center small-screen-margin-top remove-padding">
                <div className="col-12 remove-padding">
                  <div className="col-12 main-headings points d-flex font-custom-bold">
                    01
                  </div>
                  <div className="text-light col-12 points-headings">
                    Exclusive Events and Meetups
                  </div>
                  <div className="text-light col-12 points-description mt-2">
                    Exclusive meetups where top tier revenue <br />
                    professionals will connect, share insights and talk <br />
                    about the most recent happenings in the industry. <br />
                    And when you are among the best, you get a step <br />
                    closer to being the best yourself.
                  </div>
                </div>
              </div>
            </Col>
          </Col>
          <div className="col-11 d-flex justify-content-end ">
            <div className="col-9  remove-padding-left remove-padding-right">
              <img src={connectorImage} alt="" className='img-fluid image-hide' />
            </div>
          </div>
          <Col md={12} className="d-xl-flex d-lg-flex d-md-block d-sm-block position-relative align-items-center" id="alterante-container">
            <Col lg={7} xl={7} md={12} sm={12} className='small-screen-margin-top remove-padding'>
              <div className="col-12 d-flex justify-content-end  align-items-center remove-padding">
                <div className="col-12 remove-padding" style={{ maxWidth: "400px" }}>
                  <div className="col-12 main-headings d-flex font-custom-bold">
                    02
                  </div>
                  <div className="text-light col-12 points-headings">
                    Podcasts and Webinars
                  </div>
                  <div className="text-light col-12 points-description mt-2">
                    Regular webinars, podcasts, panel discussions or roundtable meetings, on relevant revenue topics.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5} xl={5} md={12} sm={12} className='d-flex justify-content-end remove-padding-right medium-screen-margin-top'>
              <Card.Img variant="top" src={podCast} className='img-fluid image-2 connecting-images-border small-screen-margin-top'
                style={{
                  maxHeight: "300px",
                  maxWidth: "480px",
                  borderRadius: "10px",
                  padding: "2px",
                  background: "linear-gradient(to right, #B6F3C9, #4763F4)",
                }} />
            </Col>
          </Col>
          <div className="col-11 d-flex justify-content-end ">
            <div className="col-9  remove-padding-left remove-padding-right">
              <img src={connectorImage2} alt="" className='img-fluid image-hide' />
            </div>
          </div>

          <Col md={12} className="d-xl-flex d-lg-flex  d-md-block  d-sm-block align-items-center">
            <Col md={12} lg={6} xl={6} sm={12} className='d-flex justify-content-end  remove-padding-right '>
              <Card.Img variant="top" src={curatedPacks}
                className='img-fluid image-3 connecting-images-border small-screen-margin-top'
                style={{
                  maxHeight: "300px",
                  maxWidth: "480px",
                  borderRadius: "10px",
                  padding: "2px",
                  background: "linear-gradient(to right, #B6F3C9, #4763F4)",
                }}
              />
            </Col>
            <Col md={12} lg={6} xl={6} sm={12} className='small-screen-margin-top remove-padding'>
              <div className="col-12 d-flex align-items-center remove-padding">
                <div className="col-12 remove-padding" >
                  <div className="col-12 main-headings d-flex font-custom-bold">
                    03
                  </div>
                  <div className="text-light col-12  points-headings">
                    Exclusive Curated Perks
                  </div>
                  <div className="text-light col-12  points-description mt-2">
                    To promote exclusivity, we will offer our members discounts and deals from our network of partner companies.
                  </div>
                </div>
              </div>
            </Col>
          </Col>
          <div className="col-11 d-flex justify-content-end ">
            <div className="col-9  remove-padding-left remove-padding-right">
              <img src={connectorImage} alt="" className='img-fluid image-hide' />
            </div>
          </div>
          <Col md={12} className="mb-4 d-xl-flex d-lg-flex  d-md-block  d-sm-block align-items-center" 
          id="alterante-container-2">
            <Col lg={7} xl={7} md={12} sm={12} className='small-screen-margin-top remove-padding'>
              <div className="col-12 d-flex justify-content-end  align-items-center remove-padding">
                <div className="col-12 remove-padding" style={{ maxWidth: "400px" }}>
                  <div className="col-12 main-headings d-flex font-custom-bold">
                    04
                  </div>
                  <div className="text-light col-12 points-headings">
                    Access to a Network Like No Other
                  </div>
                  <div className="text-light col-12 points-description mt-2">
                    Direct access to the top tier founders and revenue professionals.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5} xl={5} md={12} sm={12} className='d-flex justify-content-end remove-padding-right medium-screen-margin-top'>
              <Card.Img variant="top" src={lastImage}
                className='img-fluid image-2 connecting-images-border small-screen-margin-top'
                style={{
                  maxHeight: "300px",
                  maxWidth: "480px",
                  borderRadius: "10px",
                  padding: "2px",
                  background: "linear-gradient(to right, #B6F3C9, #4763F4)",
                }}
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ExperienceSection;

import React from 'react';
import { Col } from 'react-bootstrap';
import '../../styles/MemberSection.css';

import amazonIcon from "../../assets/images/amazon (2).png"
import adobeIcon from "../../assets/images/adobe.png"
import Linkedin from "../../assets/images/linkedin (2).png"
import americanExpressIcon from "../../assets/images/American-Express2 1.png"

import gartnerIcon from "../../assets/images/gartner.png"
import uberIcon from "../../assets/images/uber.png"
import shpifyIcon from "../../assets/images/shopify.png"
import dominosIcon from "../../assets/images/dominos.png"

import slackIcon from "../../assets/images/salck.png"
import cventIcon from "../../assets/images/cvent.png"
import xoIcon from "../../assets/images/xo.png"

import timesIcon from "../../assets/images/times.png"
import tataIcon from "../../assets/images/tata.png"
import salesFOrcesIcon from "../../assets/images/salesforces.png"
import airtelIcon from "../../assets/images/airtel.png"



const MembersSection = () => {
  return (
    <section className="members-section py-5" style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <>
        <Col className='d-xl-flex d-lg-flex d-md-flex d-sm-block col-12'>
          <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 d-flex justify-content-center"
            id="members-left-container"
            style={{ borderRight: "3px solid #C0C0C0" }}>
            <h2 className='members-heading'>
              Members have led <br />
              <span className='members-gradiant-heading'>
                revenue functions for
              </span>
            </h2>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 member-logos d-flex justify-content-between align-items-center my-4 small-screen-margin-top
            remove-left-padding remove-margin-left">
              <div className="scroll-container ">
                <div className="scroll-content">
                  <img src={amazonIcon} alt="Amazon" className='image-height' />
                  <img src={adobeIcon} alt="LinkedIn" className=' image-height' />
                  <img src={Linkedin} alt="Coursera" className='image-height' />
                  <img src={americanExpressIcon} alt="Chargebee" className='image-height' />
                  <img src={gartnerIcon} alt="Amazon" className='image-height ' />
                  <img src={uberIcon} alt="LinkedIn" className='image-height' />
                  <img src={dominosIcon} alt="Chargebee" className='image-height' />
                  <img src={shpifyIcon} alt="Coursera" className='image-height' />
                  <img src={slackIcon} alt="Amazon" className=' image-height' />
                  <img src={cventIcon} alt="LinkedIn" className='image-height' />
                  <img src={airtelIcon} alt="Coursera" className='image-height' />
                  <img src={xoIcon} alt="Coursera" className='image-height' />
                  <img src={salesFOrcesIcon} alt="LinkedIn" className='image-height' style={{ minWidth: "90px", maxHeight: "50px" }} />
                  <img src={timesIcon} alt="Chargebee" className='image-height' />
                  <img src={tataIcon} alt="Amazon" className='image-height-2' />

                  <img src={amazonIcon} alt="Amazon" className='image-height' />
                  <img src={adobeIcon} alt="LinkedIn" className=' image-height' />
                  <img src={Linkedin} alt="Coursera" className='image-height' />
                  <img src={americanExpressIcon} alt="Chargebee" className='image-height' />
                  <img src={gartnerIcon} alt="Amazon" className='image-height ' />
                  <img src={uberIcon} alt="LinkedIn" className='image-height' />
                  <img src={dominosIcon} alt="Chargebee" className='image-height' />
                  <img src={shpifyIcon} alt="Coursera" className='image-height' />
                  <img src={slackIcon} alt="Amazon" className=' image-height' />
                  <img src={cventIcon} alt="LinkedIn" className='image-height' />
                  <img src={airtelIcon} alt="Coursera" className='image-height' />
                  <img src={xoIcon} alt="Coursera" className='image-height' />
                  <img src={salesFOrcesIcon} alt="LinkedIn" className='image-height' style={{ minWidth: "90px", maxHeight: "50px" }} />
                  <img src={timesIcon} alt="Chargebee" className='image-height' />
                  <img src={tataIcon} alt="Amazon" className='image-height-2' />
                </div>
              </div>

          </div>
        </Col>
      </>

    </section>
  );
}

export default MembersSection;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from '../components/pages/CustomNavbar';
import HeroSection from '../components/pages/HeroSection';
import MembersSection from '../components/pages/MembersSection';
import JoinSection from '../components/pages/JoinSection';
import ExperienceSection from '../components/pages/ExperienceSection';
import ReadyToJoinSection from '../components/pages/ReadyToJoinSection ';
import FormPage from '../components/pages/Form'
import Footer from '../components/pages/Footer';
import ConfirmedRevFolksSection from '../components/pages/ConfirmedRevFolksSection';

const AllRoutes = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={
                    <>
                        <Navbar />
                        <HeroSection />
                        <MembersSection />
                        <JoinSection />
                        <ExperienceSection />
                        <ConfirmedRevFolksSection/>
                        <ReadyToJoinSection />
                        <Footer />
                    </>
                } />
                <Route path="/form" element={
                    <>
                        <FormPage />
                        <Footer />
                    </>
                } />
            </Routes>
        </div>
    );
}

export default AllRoutes;

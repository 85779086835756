import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../../styles/Navbar.css';
import revFoxLogo from "../../assets/images/TheRevFolks1 1.png"
import { useNavigate } from 'react-router-dom';


const CustomNavbar = () => {

  return (
    <div className='col-12 d-flex justify-content-center' style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}>
      <Navbar className='col-xl-10 col-lg-10 col-md-12 col-sm-12' variant="" expand="lg" >
        <div className="col ">
          <Navbar.Brand href="#" className="brand">
            <img style={{ maxWidth: "300px" }} src={revFoxLogo} alt="" />
          </Navbar.Brand>
        </div>
        <div className="col d-xl-flex d-lg-flex d-md-flex d-sm-block justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-center ">
          <Nav className="ml-auto" id="nav-apply-now-btn-container">
            <Nav.Link href="/form" 
                className="btn-apply-now d-flex align-items-center justify-content-center " 
                id="nav-apply-now-btn" 
                style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
              Apply now
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;


import '../../styles/HeroSection.css';
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../styles/HeroSection.css';
import profileIcon from "../../assets/images/profile.png"
import { useNavigate } from 'react-router-dom';


const HeroSection = () => {

    const navigate = useNavigate();

    return (
        <section className="hero-section text-center text-white d-flex ">
            <Container>
                <Row>
                    <Col>
                        <h1 className='hero-heading'>
                            India’s Most Elite
                        </h1>
                        <h1 className="hero-heading hero-gradient-heading" >
                            Revenue Community
                        </h1>
                        <p className='mt-3'>TheRevFolks is a community where Leadership, Insights, <br /> and Opportunities converge to fuel Growth and Success</p>
                        <div className="col-12  d-xl-flex d-lg-flex d-md-flex d-sm-block  justify-content-center align-items-center mt-5 small-screen-display-block ">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center remove-padding-right waitlist-profile center-container" >
                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-6 d-flex justify-content-end align-items-center ">
                                    <img src={profileIcon} alt="Profile" className="profile-image" />
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6  remove-padding-left">
                                    <div className="justify-content-start align-items-center  text-start">
                                        <span className="profile-count">120+ <br /> </span>
                                        <span className='font-weight-bold'> Profiles waitlisted </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 btn-container center-container small-screen-margin-top">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <Button variant="light" className="second-btn-apply" onClick={() => navigate("/form")}>Apply now</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HeroSection;
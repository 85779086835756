import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../styles/JoinSection.css';

const JoinSection = () => {
  return (
    <section className="join-section py-5 " style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}>
      <div className='mt-3'>
        <Col className="text-center text-light">
          <h1 className="main-headings mt-5">
            Who can join <span className="main-headings-gradient">TheRevFolks</span>?
          </h1>
        </Col>
      </div>
      <div className=' pl-5 px-5' >
        <Row className="mt-5 pt-3">
          <Col md={6} xl={3} lg={3} sm={12}>
            <Card className="text-center custom-card">
              <Card.Body>
                <Card.Text className='card-text-custom'>CXOs from companies <br /> having revenue</Card.Text>
                <Card.Title className="main-headings">$10 Mn+</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3} lg={3} sm={12}>
            <Card className="text-center custom-card">
              <Card.Body>
                <Card.Text className='card-text-custom'>Functional Heads <br /> handling revenue</Card.Text>
                <Card.Title className="main-headings" >$1 Mn+</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3} lg={3} sm={12}>
            <Card className="text-center custom-card">
              <Card.Body>
                <Card.Text className='card-text-custom'>Founders leading <br /> revenue</Card.Text>
                <Card.Title className="main-headings">$5 Mn+</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} xl={3} lg={3} sm={12}>
            <Card className="text-center custom-card">
              <Card.Body>
                <Card.Text className='card-text-custom'>Founders having <br /> previous exit</Card.Text>
                <Card.Title className="main-headings">$10 Mn+</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div className='col-12 d-flex justify-content-center align-items-center mt-5'>
        <div className="col-4 mt-3" style={{ border: "3px solid rgba(43, 43, 43, 1)" }}>

        </div>
      </div>
    </section>
  );
};

export default JoinSection;

import React, { useEffect, useState } from "react";
import "../../styles/Form.css";
import { Navbar, Toast } from "react-bootstrap";
import "../../styles/Navbar.css";
import revFoxLogo from "../../assets/images/TheRevFolks1 1.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    emailId: "",
    whatsappNumber: "",
    whoAreYou: "0",
    invitedBy: "",
    joinReasons: {
      networking: false,
      industryInsights: false,
      professionalDevelopment: false,
      other: false,
    },
    joinReasonsOtherText: "",
    valueAddition: {
      sharingExpertise: false,
      leadingDiscussions: false,
      mentoring: false,
      other: false,
    },
    valueAdditionOtherText: "",
    revenues: {
      tenM: false,
      oneM: false,
      fiveM: false,
      tenMExit: false,
    },
  });

  const [toastDisplayed, setToastDisplayed] = useState(false);
  let navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: "",
    emailId: "",
    whatsappNumber: "",
    whoAreYou: "",
    linkedInId: "",
    currentCompany: "",
    designation: "",
    invitedBy: "",
    joinReasons: "",
    valueAddition: "",
    joinReasonsOtherText: "",
    valueAdditionOtherText: "",
    revenues: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newErrors = { ...errors }; // Create a copy of the current errors state

    if (type === "checkbox") {
      setFormData((prevState) => {
        const updatedFormData = {
          ...prevState,
          [name]: {
            ...prevState[name],
            [value]: checked,
          },
        };

        // empty value if other is not checked
        if (name === "joinReasons" && value === "other" && !checked) {
          updatedFormData.joinReasonsOtherText = "";
        }
        if (name === "valueAddition" && value === "other" && !checked) {
          updatedFormData.valueAdditionOtherText = "";
        }

        if (name === "revenues" && !checked) {
          updatedFormData.revenues = "";
        }

        // Validate the checkbox selections
        // const isJoinReasonsSelected = Object.values(updatedFormData.joinReasons).some(val => val === true);
        // const isValueAdditionSelected = Object.values(updatedFormData.valueAddition).some(val => val === true);

        // if (!isJoinReasonsSelected) {
        //   newErrors.joinReasons = 'At least one reason is required';
        // } else {
        //   newErrors.joinReasons = '';
        // }

        // if (!isValueAdditionSelected) {
        //   newErrors.valueAddition = 'At least one value is required';
        // } else {
        //   newErrors.valueAddition = '';
        // }

        // if (updatedFormData.joinReasons.other && !updatedFormData.joinReasonsOtherText) {
        //   newErrors.joinReasonsOtherText = 'Please specify';
        // } else {
        //   newErrors.joinReasonsOtherText = '';
        // }

        // if (updatedFormData.valueAddition.other && !updatedFormData.valueAdditionOtherText) {
        //   newErrors.valueAdditionOtherText = 'Please specify';
        // } else {
        //   newErrors.valueAdditionOtherText = '';
        // }

        setErrors(newErrors);
        return updatedFormData;
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      if (!value) {
        // Add error if input is empty
        if (name === "name") newErrors.name = "Name is required";
        if (name === "emailId") newErrors.emailId = "Email is required";
        if (name === "whatsappNumber")
          newErrors.whatsappNumber = "Whatsapp Number is required";
        if (name === "whoAreYou")
          newErrors.whoAreYou = "Who are you is required";
        if (name === "linkedInId")
          newErrors.linkedInId = "LinkedIn Id is required";
        if (name === "currentCompany")
          newErrors.currentCompany = "Current company name is required";
        if (name === "designation")
          newErrors.designation = "Designation is required";
        if (name === "joinReasonsOtherText")
          newErrors.joinReasonsOtherText = "Please specify";
        if (name === "valueAdditionOtherText")
          newErrors.valueAdditionOtherText = "Please specify";
        if (name === "revenues") newErrors.revenues = "Please specify";
      } else {
        // Clear error if input is not empty
        if (name === "name") newErrors.name = "";
        if (name === "emailId") newErrors.emailId = "";
        if (name === "whatsappNumber") newErrors.whatsappNumber = "";
        if (name === "whoAreYou") newErrors.whoAreYou = "";
        if (name === "linkedInId") newErrors.linkedInId = "";
        if (name === "currentCompany") newErrors.currentCompany = "";
        if (name === "designation") newErrors.designation = "";
        if (name === "joinReasonsOtherText")
          newErrors.joinReasonsOtherText = "";
        if (name === "valueAdditionOtherText")
          newErrors.valueAdditionOtherText = "";
        if (name === "revenues") newErrors.revenues = "";
      }

      // Additional email validation
      if (name === "emailId") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          newErrors.emailId = "Email is invalid";
        } else {
          newErrors.emailId = "";
        }
      }

      if (name === "whatsappNumber") {
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(value)) {
          newErrors.whatsappNumber = "Phone number must be 10 digits";
        } else {
          newErrors.whatsappNumber = "";
        }
      }

      setErrors(newErrors);
    }
  };

  const validate = () => {
    let valid = true;
    let newErrors = {
      name: "",
      emailId: "",
      whatsappNumber: "",
      whoAreYou: "",
      linkedInId: "",
      currentCompany: "",
      designation: "",
      invitedBy: "",
      joinReasons: "",
      valueAddition: "",
      joinReasonsOtherText: "",
      valueAdditionOtherText: "",
      revenues: "",
    };

    if (!formData.name) {
      newErrors.name = "Name is required";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.emailId) {
      newErrors.emailId = "Email is required";
      valid = false;
    } else if (!emailRegex.test(formData.emailId)) {
      newErrors.emailId = "Email is invalid";
      valid = false;
    }

    if (!formData.whatsappNumber) {
      newErrors.whatsappNumber = "Whatsapp Number is required";
      valid = false;
    }

    if (!formData.linkedInId) {
      newErrors.linkedInId = "LinkedIn Id is required";
      valid = false;
    }

    if (!formData.currentCompany) {
      newErrors.currentCompany = "Current company name is required";
      valid = false;
    }

    if (!formData.designation) {
      newErrors.designation = "Designation is required";
      valid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.whatsappNumber)) {
      newErrors.whatsappNumber = "Phone number must be 10 digits";
      valid = false;
    }

    if (!formData.whoAreYou || formData.whoAreYou === "0") {
      newErrors.whoAreYou = "Who are you is required";
      valid = false;
    }

    const isJoinReasonsSelected = Object.values(formData.joinReasons).some(
      (val) => val === true || val
    );

    if (!isJoinReasonsSelected) {
      newErrors.joinReasons = "At least one reason is required";
      valid = false;
    }

    if (formData.joinReasons.other && !formData.joinReasonsOtherText) {
      newErrors.joinReasonsOtherText = "Please specify";
      valid = false;
    }

    const isValueAdditionSelected = Object.values(formData.valueAddition).some(
      (val) => val === true || val
    );
    if (!isValueAdditionSelected) {
      newErrors.valueAddition = "At least one value is required";
      valid = false;
    }

    if (formData.valueAddition.other && !formData.valueAdditionOtherText) {
      newErrors.valueAdditionOtherText = "Please specify";
      valid = false;
    }

    const isRevenueSelected = Object.values(formData.revenues).some(
      (val) => val === true || val
    );
    if (!isRevenueSelected) {
      newErrors.revenues = "At least one option is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      axios
        .post(`https://api.swageazy.com/revfolks/submit`, { formData })
        .then((res) => {
          if (res.data.success) {
            if (!toastDisplayed) {
              setToastDisplayed(true);
              toast.success(
                "Submitted Successfully",
                {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  onClose: () => setToastDisplayed(false),
                },
                1000
              );

              setTimeout(() => {
                navigate("/");
              }, 1500);
            }
          }
        })
        .catch((err) => {
          if (!toastDisplayed) {
            setToastDisplayed(true);
            toast.error(
              err?.response?.data?.message || "Something went wrong",
              {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: () => setToastDisplayed(false),
              },
              1000
            );
          }
        });
    }
  };

  return (
    <div className="row m-0">
      <div
        className="col-12 d-flex justify-content-center"
        style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}
      >
        <ToastContainer />
        <Navbar
          className="col-xl-10 col-lg-10 col-md-12 col-sm-12"
          variant=""
          expand="lg"
        >
          <div className="col ">
            <Navbar.Brand href="/" className="brand">
              <img style={{ maxWidth: "300px" }} src={revFoxLogo} alt="" />
            </Navbar.Brand>
          </div>
        </Navbar>
      </div>
      <div className="col-12 col-xl-6 col-md-6 col-lg-6 m-auto mt-3 mb-5 ">
        <div
          className="card form-card"
          style={{ background: "#1C1C1C", border: "1px solid #5C5C5C" }}
        >
          <div className="card-body">
            <button
              type="button"
              className="close-btn"
              onClick={() => navigate("/")}
            >
              <i className="fas fa-times"></i>
            </button>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="name">
                 Full name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your full name"
                />
                {errors.name && (
                  <div className="error text-danger">{errors.name}</div>
                )}
              </div>
              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="whatsappNumber">
                  Whatsapp No.
                </label>
                <input
                  type="number"
                  id="whatsappNumber"
                  name="whatsappNumber"
                  className={`form-control ${
                    errors.whatsappNumber ? "is-invalid" : ""
                  }`}
                  value={formData.whatsappNumber}
                  onChange={handleChange}
                  placeholder="Your whatsapp number"
                />
                {errors.whatsappNumber && (
                  <div className="error text-danger">
                    {errors.whatsappNumber}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="emailId">
                  Email ID
                </label>
                <input
                  type="email"
                  id="emailId"
                  name="emailId"
                  className={`form-control ${
                    errors.emailId ? "is-invalid" : ""
                  }`}
                  value={formData.emailId}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {errors.emailId && (
                  <div className="error text-danger">{errors.emailId}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="whoAreYou">
                  Who are you?
                </label>
                <select
                  id="whoAreYou"
                  required
                  className={`form-control ${
                    errors.whoAreYou ? "is-invalid" : ""
                  }`}
                  value={formData.whoAreYou}
                  onChange={handleChange}
                  name="whoAreYou"
                >
                  <option value="0"></option>
                  <option value="founders_leading_revenue">
                    Founders leading revenue
                  </option>
                  <option value="revenue_professional">
                    Revenue professional
                  </option>
                </select>
                {errors.whoAreYou && (
                  <div className="error text-danger">{errors.whoAreYou}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="emailId">
                  LinkedIn ID
                </label>
                <input
                  type="text"
                  id="linkedInId"
                  name="linkedInId"
                  className={`form-control ${
                    errors.linkedInId ? "is-invalid" : ""
                  }`}
                  value={formData.linkedInId}
                  onChange={handleChange}
                  placeholder="Enter your LinkedIn Id"
                />
                {errors.linkedInId && (
                  <div className="error text-danger">{errors.linkedInId}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="currentCompany">
                  Current company
                </label>
                <input
                  type="text"
                  id="currentCompany"
                  name="currentCompany"
                  className={`form-control ${
                    errors.currentCompany ? "is-invalid" : ""
                  }`}
                  value={formData.currentCompany}
                  onChange={handleChange}
                  placeholder="Enter your current company name"
                />
                {errors.currentCompany && (
                  <div className="error text-danger">{errors.currentCompany}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="designation">
                  Designation
                </label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  className={`form-control ${
                    errors.designation ? "is-invalid" : ""
                  }`}
                  value={formData.designation}
                  onChange={handleChange}
                  placeholder="Enter your designation"
                />
                {errors.designation && (
                  <div className="error text-danger">{errors.designation}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels">
                  Why do you want to join TheRevFolks?
                </label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="networking"
                    name="joinReasons"
                    value="networking"
                    checked={formData.joinReasons.networking}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="networking">
                    Networking opportunity
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="industryInsights"
                    name="joinReasons"
                    value="industryInsights"
                    checked={formData.joinReasons.industryInsights}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="industryInsights"
                  >
                    Access to industry insights and trends
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="professionalDevelopment"
                    name="joinReasons"
                    value="professionalDevelopment"
                    checked={formData.joinReasons.professionalDevelopment}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="professionalDevelopment"
                  >
                    Professional Development
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="joinReasonsOther"
                    name="joinReasons"
                    value="other"
                    checked={formData.joinReasons.other}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="joinReasonsOther"
                  >
                    Other
                  </label>
                  {formData.joinReasons.other && (
                    <input
                      type="text"
                      id="joinReasonsOtherText"
                      name="joinReasonsOtherText"
                      className={`form-control ${
                        errors.joinReasonsOtherText ? "is-invalid" : ""
                      }`}
                      value={formData.joinReasonsOtherText}
                      onChange={handleChange}
                      placeholder="Please specify"
                    />
                  )}
                  {formData.joinReasons.other &&
                    errors.joinReasonsOtherText && (
                      <div className="error text-danger">
                        {errors.joinReasonsOtherText}
                      </div>
                    )}
                </div>
                {errors.joinReasons && (
                  <div className="error text-danger">{errors.joinReasons}</div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels">
                  How will you add value to TheRevFolks?
                </label>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="sharingExpertise"
                    name="valueAddition"
                    value="sharingExpertise"
                    checked={formData.valueAddition.sharingExpertise}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sharingExpertise"
                  >
                    Sharing expertise and experience
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="leadingDiscussions"
                    name="valueAddition"
                    value="leadingDiscussions"
                    checked={formData.valueAddition.leadingDiscussions}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="leadingDiscussions"
                  >
                    Leading discussions and Initiatives
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="mentoring"
                    name="valueAddition"
                    value="mentoring"
                    checked={formData.valueAddition.mentoring}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="mentoring">
                    Mentoring community members
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    id="valueAdditionOther"
                    name="valueAddition"
                    value="other"
                    checked={formData.valueAddition.other}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="valueAdditionOther"
                  >
                    Other
                  </label>
                  {formData.valueAddition.other && (
                    <input
                      type="text"
                      id="valueAdditionOtherText"
                      name="valueAdditionOtherText"
                      className={`form-control ${
                        errors.valueAdditionOtherText ? "is-invalid" : ""
                      }`}
                      value={formData.valueAdditionOtherText}
                      onChange={handleChange}
                      placeholder="Please specify"
                    />
                  )}
                  {formData.valueAddition.other &&
                    errors.valueAdditionOtherText && (
                      <div className="error text-danger">
                        {errors.valueAdditionOtherText}
                      </div>
                    )}
                </div>
                {errors.valueAddition && (
                  <div className="error text-danger">
                    {errors.valueAddition}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels">
                  Select Criteria
                </label>
                <div className="form-check">
                  <input
                    type="radio"
                    id="CXOs10Mn+"
                    name="revenues"
                    value="CXOs10Mn+"
                    checked={formData.revenues.tenM}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label className="form-check-label" htmlFor="CXOs10Mn+">
                    CXOs from companies having revenue $10 Mn+
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="FunctionalHeads1Mn+"
                    name="revenues"
                    value="FunctionalHeads1Mn+"
                    checked={formData.revenues.oneM}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="FunctionalHeads1Mn+"
                  >
                    Functional Heads handling revenue $1 Mn+
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="FounderLeding5Mn+"
                    name="revenues"
                    value="FounderLeding5Mn+"
                    checked={formData.revenues.fiveM}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="FounderLeding5Mn+"
                  >
                    Founders leading revenue $5 Mn+
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    id="FoundersHavingExit10Mn+"
                    name="revenues"
                    value="FoundersHavingExit10Mn+"
                    checked={formData.revenues.tenMExit}
                    onChange={handleChange}
                    className="form-check-input"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="FoundersHavingExit10Mn+"
                  >
                    Founders having previous exit $10 Mn+
                  </label>
                </div>
                {errors.revenues && (
                  <div className="error text-danger">
                    {errors.revenues}
                  </div>
                )}
              </div>

              <div className="form-group">
                <label className="d-flex bold-labels" htmlFor="invitedBy">
                  Invited by (optional)
                </label>
                <input
                  type="text"
                  id="invitedBy"
                  name="invitedBy"
                  className="form-control"
                  value={formData.invitedBy}
                  onChange={handleChange}
                  placeholder="Person who invited you"
                />
              </div>

              <button
                type="submit"
                className="submit-button d-block m-auto mt-5"
              >
                Submit request
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;

import React from 'react';
import '../../styles/Slider.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import swageazySlider from "../../assets/images/swageazy-slider-1.png"
import salesforcesSlider from "../../assets/images/salesforces-slider2.png"
import dominosSlider from "../../assets/images/dominos-slider-2.png"
import xoSlider from "../../assets/images/xo-slider-2.png";
import leftArrow from '../../assets/images/left-arrow.png';
import rightArrow from '../../assets/images/right-arrow.png';

import adani from "../../assets/images/adani.png"
import even from "../../assets/images/even.png"
import googleCloud from "../../assets/images/googlecloud.png";
import internshala from '../../assets/images/internshala.png';
import recur from "../../assets/images/recur.png"
import revolut from "../../assets/images/revolut.png";
import zepto from '../../assets/images/zepto.png';
import zippee from '../../assets/images/zippee.png';

import digitalStack from '../../assets/images/DigitalStack.png';
import mentzaNew from '../../assets/images/mentzaNew.png';
import noiseNew from '../../assets/images/noiseNew.png';
import pazCareNew from '../../assets/images/pazCareNew.png';
import springworksNew from '../../assets/images/springworks-latest.png';
import thetimesNEW from '../../assets/images/thetimesNEW.png';
import xoxoNew from '../../assets/images/xoxoNew.png';
import simensNew from '../../assets/images/simensNew.png';


const ConfirmedRevFolksSection = () => {
    const images = [
        swageazySlider,
        salesforcesSlider,
        dominosSlider,
        xoSlider,
        adani,
        even,
        googleCloud,
        internshala,
        recur,
        revolut,
        zepto,
        zippee,
        digitalStack,
        mentzaNew,
        noiseNew,
        pazCareNew,
        springworksNew,
        thetimesNEW,
        xoxoNew,
        simensNew
    ];

    const renderPrevButton = ({ isDisabled }) => (
        <button className="prev-button" disabled={isDisabled}>
            <img src={leftArrow} alt="Previous" />
        </button>
    );

    const renderNextButton = ({ isDisabled }) => (
        <button className="next-button" disabled={isDisabled}>
            <img src={rightArrow} alt="Next" />
        </button>
    );

    const items = images.map((src, index) => (
        <div key={index} className="item">
            <img src={src} alt={`Slide ${index}`} className="carousel-img" />
        </div>
    ));


    return (
        <>
            <div className="col-12 text-center mt-5">
                <h1 className='slider-heading-main-headings-gradient main-headings'>
                    TheRevFolks <span className='slider-heading' style={{ color: '#FFFFFF' }}>confirmed so far...
                    </span>
                </h1>
            </div>
            <div className="carousel-wrapper p-lg-5 mt-4 container ">
                <AliceCarousel
                    mouseTracking
                    items={items}
                    renderPrevButton={renderPrevButton}
                    renderNextButton={renderNextButton}
                    responsive={{
                        0: { items: 1 },
                        600: { items: 2 },
                        1024: { items: 4 },
                    }}
                    autoPlay
                    autoPlayInterval={3000}
                    infinite

                />
            </div>
        </>
    );
};

export default ConfirmedRevFolksSection;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../styles/ReadyToJoinSection.css';
import footerImage from "../../assets/images/TheRevFolks.png"
import { useNavigate } from 'react-router-dom';

const ReadyToJoinSection = () => {
  const navigate = useNavigate();

  return (
    <section className="ready-to-join-section text-center py-5 text-white">
      <Container>
        <Row className=''>
          <Col className='footer-image-container'>
            <img src={footerImage} alt="" className='footer-image img-fluid' />
            <div className="col-12" style={{marginTop:"-6rem"}}>
              <h1 className='main-headings small-screen-margin-top'>Ready to join <span className='main-headings-gradient '>TheRevFolks?</span> </h1>
              <button className='mt-5 bottom-btn-apply' variant="danger" onClick={()=> navigate("/form")}>Apply now</button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ReadyToJoinSection;
